import {useEffect} from "react";
import Header from "../components/Header2";
import Footer from "../components/Footer2";

export default function About() {

useEffect(() => {
   document.title = "About us - 10xcoder"
}, []);

return (
<>
<Header />

<div className="container-fluid" id="contact">
	<div className="container">
		<div className="row" style={{marginBottom: "96px"}}>
			<div className="col-md-3"></div>
			<div className="col mt-5">

				<h2 style={{fontWeight: "600"}}>About 10xcoder</h2>
				<p style={{color: "#777"}}>10xcoder services is a 21st century software development house. 
				We specialize in making Minimum Viable Products (MVPs). We have helped global brands around the world to 
				launch new business verticles in a seemless manner. </p>
				<p>Click below links to know more:</p>
				<p><a href="/why">Why 10xcoder is best suited for your software development needs? </a></p>
				<p><a href="/roadmap">MVP development roadmap at 10xcoder</a></p>
				<p><a href="/privacy-policy">Privacy Policy</a></p>
				<p><a href="/terms-and-conditions">Terms and Conditions</a></p>
				<p><a href="/refund-policy">Refund Policy</a></p>

			</div>
			<div className="col-md-3"></div>
		</div>			
	</div>
</div>

<Footer />
	</>
	);
	}